<template>
  <div class="more" ref="container">
    <div v-if="teacherList && teacherList.length > 0">
      <ul class="main-container">
        <li v-for="(item, i) in teacherList" :key="i">
          <router-link
            :to="{
                path: `/community/teacher/${item.care_id}`,
                query: {name: item.nickname}
              }"
          >
            <img :src="item.avatar" alt="item.nickname" />
            <div class="text">
              <h4>{{ item.nickname }}</h4>
              <p>
                {{ item.position }}
                <br />
                <span>粉丝数：{{ item.care_num }}</span>
              </p>
            </div>
            <em><i class="jym-icon-test82"></i>{{ "已关注" }}</em>
          </router-link>
        </li>
      </ul>
      <Loading :loadend="loadend" :loading="loading"></Loading>
    </div>
    <div v-else class="emptyData" v-show="initLoading">
      <img src="@assets/images/emptyData.png" />
      <p>暂无数据</p>
    </div>
    
  </div>
</template>

<script>
import { myCare } from "@api/user";

export default {
  name: "mycare",
  data() {
    return {
      page: 1,
      limit: 20,
      teacherList: [],
      loading: false,
      loadend: false,
      initLoading: false
    };
  },
  mounted() {
    this.loadMore();
    this.$scroll(this.$refs.container, () => {
      !this.loading && this.loadMore();
    });
  },
  methods: {
    loadMore() {
      let that = this;
      
      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;    
      myCare(that.page, that.limit).then(res => {
        that.loading = false;
        //apply();js将一个数组插入另一个数组;
        that.teacherList.push.apply(that.teacherList, res.data);
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
        that.initLoading = true;
      })
      .catch(res => {
        this.$dialog.toast({ mes: res.msg });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.more {
  ul {
    background: white;
    li {
      width: 100%;
      padding: 0.2rem 0.3rem;
      border-top: 0.1rem solid #f5f5f5;
      display: flex;
      justify-content: space-between;
      position: relative;
      a {
        width: 100%;
        display: flex;
        justify-content: space-between;
        img {
          height: 0.6rem;
          width: 0.6rem;
          border-radius: 100%;
          position: absolute;
          top: 0.2rem;
          left: 0.3rem;
        }
        em {
          line-height: 1rem;
          color: #A9A9A9;
          font-size: .26rem;
          i{
            font-size: .36rem;
          }
        }
        .text {
          padding-left: 0.8rem;
          h4 {
            font-size: 0.26rem;
          }
          p {
            font-size: 0.24rem;
            span {
              color: #a9a9a9;
            }
          }
        }
      }
    }
    .more_loading {
      background: #f5f5f5;
    }
  }
}
</style>
